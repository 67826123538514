<template>
  <div id="LatestJudgementDetails" class="m-6 statues" >


   <section class="">
       <h3 class="mb-5 heading2">  <strong> Subjects : {{browseBySubject.length}} </strong> </h3>
        <BrowseBySubject  :browseSubject="browseBySubject"/>
   </section>
  </div>
</template>
<script>
import BrowseBySubject from "@/components/BrowseBySubject.vue";
const axios = require('axios');
import "../../assets/style/custom.css"
export default {
    name: 'Subjects',
    components:{BrowseBySubject },
    props: {
      slugid: {
        default: null,
      },
  },
  data() {
      return {
          isActive: true,
          browseBySubject:[],
      }
    },
    mounted (){
          this.getBrowseSubject();

    },
    methods:{
          getBrowseSubject(){
        const loadingComponent = this.$buefy.loading.open({
                    container: this.isFullPage
        })
          axios.get('https://staging.spotlawapp.com/newapi/browseBySubject.php').then( res=> {
                this.browseBySubject = res.data.data;
                loadingComponent.close();
          })

        }

    }

}
</script>

