  <template>
    <div class="summary">
      <div class="columns">
          <div class="column browse-subject-col">
               <div class="columns statues-col" v-for="subject in browseSubject" :key="subject.idStatute">
                <div class="column">

                   {{subject.sSubTxt}} <br>
                  <span class="grey-color"> {{subject.jCount}} judgement(s) </span>

                </div>
                <div class="column has-text-right pt-5" >
                   <router-link to="#" class=" browse-icon"> <i class="fa fa-chevron-right" aria-hidden="true"></i> </router-link>
                </div>

               </div>
          </div>

      </div>

    </div>
</template>
<script>
// import "../../assets/style/custom.css"
export default {
  name: 'browseSubject',
  props:{
    browseSubject: []
  }

}
</script>
